import { CompanyInfos } from "@/types/CompanyType";

type MenuItem = {
  text: string,
  tag: string,
  iconNameWithClass: string,
  subMenus?: {
    link: string,
    text: string,
    tag?: string,
    flag?: string,
    isEnabled?: boolean,
    unavailabilityPageLink?: string
  }[],
  link?: string,
  isEnabled?: boolean,
  unavailabilityPageLink?: string
}

const getMenuItems = (companyInfos: CompanyInfos) => {
  const menus: MenuItem[] = [
    {
      text: "Demandes à Valider",
      tag: "DEMANDS",
      iconNameWithClass: "ut-spe-check-square",
      // isEnabled: companyInfos?.have_collabo_space_subscription ?? false,
      isEnabled: true,
      unavailabilityPageLink: "/validations/a-propos",
      subMenus: [
        {
          link: "/validations/absences",
          tag: "ABSENCES",
          text: "Absences",
          flag: "approbation_features",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
        /*{
          link: "/validations/avances-sur-salaire",
          tag: "ADVANCES",
          text: "Avances sur salaire",
          flag: "approbation_features"
        },*/
        {
          link: "/validations/acomptes-sur-salaire",
          tag: "ACOMPTES",
          text: "Acomptes sur salaire",
          flag: "approbation_features",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
      ],
    },
    {
      text: "Congés et Absences",
      tag: "LEAVES_AND_ABSENCES",
      iconNameWithClass: "ut-spe-calendar",
      isEnabled: true,
      unavailabilityPageLink: "",
      subMenus: [
        {
          link: "/conges-et-absences/synthesis",
          text: "Synthèse",
          isEnabled: true,
          unavailabilityPageLink: "",
          // flag: "absence_synthesis"
        }, {
          link: "/conges-et-absences/conges",
          text: "Congés Payés",
          isEnabled: true,
          unavailabilityPageLink: "",
          // flag: "absence_conge_leave_monitoring"
        },
        {
          link: "/conges-et-absences/absences",
          text: "Absences",
          isEnabled: true,
          unavailabilityPageLink: "",
          // flag: "absence_leave_monitoring"
        },
        {
          link: "/conges-et-absences/planning",
          text: "Planning",
          isEnabled: true,
          unavailabilityPageLink: "",
          // flag: "absence_planning"
        },
      ],
    },
    {
      text: "Elements de rémunération",
      tag: "COMPENSATION_ELEMENTS",
      iconNameWithClass: "ut-spe-wallet",
      isEnabled: true,
      unavailabilityPageLink: "",
      subMenus: [{
        link: "/element-de-remuneration/primes",
        text: "Primes",
        isEnabled: true,
        unavailabilityPageLink: "",
        // flag: "compensation_element_award"
      },
        // {
        //   link: "/element-de-remuneration/note-de-frais",
        //   text: "Note de frais",
        // },
        {
          link: "/element-de-remuneration/indemnite-non-soumise",
          text: "Indemnités non soumises",
          isEnabled: true,
          unavailabilityPageLink: "",
          // flag: "compensation_element_indemnity"
        },
        {
          link: "/element-de-remuneration/acompte-sur-salaire",
          text: "Acomptes sur salaire",
          isEnabled: true,
          unavailabilityPageLink: "",
          // flag: "compensation_salary_acompte"
        },
        {
          link: "/element-de-remuneration/prets",
          text: "Prêts salariés",
          isEnabled: companyInfos?.have_collabo_space_subscription ?? false,
          unavailabilityPageLink: "/element-de-remuneration/a-propos-des-prets",
          // flag: "compensation_salary_advance"
        },
        {
          link: "/element-de-remuneration/deductions",
          text: "Déductions",
          isEnabled: true,
          unavailabilityPageLink: "",
          // flag: "compensation_element_deduction"
        },
      ],
    },
    // {
    //   text: "Activités et temps de travail",
    //   tag: "DEMANDS",
    //   iconNameWithClass: "ut-spe-paper",
    //   subMenus: [{
    //     link: "/temps-et-activite/heure-suplementaire",
    //     text: "Heures supplémentaires",
    //     flag: "heures_supplementaires"
    //   }
    //   ],
    // },
    {
      text: "Bulletins de salaire",
      tag: "PAY_SLIPS",
      iconNameWithClass: "ut-spe-paper",
      isEnabled: true,
      unavailabilityPageLink: "",
      subMenus: [
        {
          link: "/bulletin-de-salaire",
          text: "Bulletins du mois",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
        {
          link: "/bulletin-de-salaire/historique",
          text: "Historique de vos bulletins",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
        // {
        //   link: "/cloturer ma paie",
        //   text: "Clôturer ma paie",
        // },
      ],
    },
    {
      text: "Déclarations et Organismes",
      tag: "DECLARATIONS_AND_ORGANIZATIONS",
      iconNameWithClass: "ut-business",
      isEnabled: true,
      unavailabilityPageLink: "",
      subMenus: [
        {
          link: "/declaration-et-organisme/synthese",
          text: "Synthèse",
          isEnabled: true,
          unavailabilityPageLink: "",
        }, {
          link: "/declaration-et-organisme/cnps",
          text: "Cotisations  sociales (CNPS, CMU, ...)",
          isEnabled: true,
          unavailabilityPageLink: "",
        }, {
          link: "/declaration-et-organisme/impots-sur-salaire",
          text: "Impôts sur salaire",
          isEnabled: true,
          unavailabilityPageLink: "",
        }, {
          link: "/declaration-et-organisme/taxes",
          text: "Taxes FDFP",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
        // {
        //   link: "/declaration-et-organisme/aib",
        //   text: "AIB",
        // },

        // {
        //   link: "/link",
        //   text: "Mutuelle",
        // }, {
        //   link: "/link",
        //   text: "Prévoyance",
        // }, {
        //   link: "/link",
        //   text: "Retraite complémenatire",
        // }
      ],
    },
    {
      text: "Documents",
      tag: "DOCUMENTS",
      iconNameWithClass: "ut-spe-folder",
      isEnabled: true,
      unavailabilityPageLink: "",
      subMenus: [
        {
          link: "/documents/",
          text: "Documents entreprises",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
        {
          link: "/corbeille/",
          text: "Corbeille",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
      ],
    },
    {
      text: "Gestion du personnel",
      tag: "PERSONNEL_MANAGEMENT",
      iconNameWithClass: "ut-spe-team",
      isEnabled: true,
      unavailabilityPageLink: "",
      subMenus: [
        {
          link: "/gestion-du-personnel/equipes",
          text: "Équipes",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
        {
          link: "/gestion-du-personnel/annuaire-collaborateurs",
          text: "Annuaire des collaborateurs",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
        {
          link: "/gestion-du-personnel/registre-collaborateurs",
          text: "Registre des collaborateurs",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
        {
          link: "/gestion-du-personnel/organigramme",
          text: "Organigramme",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
      ],
    },
    {
      text: "Paiement des salaires",
      tag: "SALARY_PAYMENTS",
      iconNameWithClass: "ut-credit-card",
      isEnabled: true,
      unavailabilityPageLink: "",
      subMenus: [
        {
          link: "/paiement-des-salaires/modes-de-paiement",
          text: "Modes de paiement",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
        {
          link: "/paiement-des-salaires/etats-de-paiement",
          text: "États de paiement des salaires",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
        {
          link: "/paiement-des-salaires/virements-bancaires",
          text: "Virements bancaires des salaires",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
        {
          link: "/paiement-des-salaires/virements-mobile-money",
          text: "Virements mobile money",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
        // {
        //   link: "/paiement-des-salaires/parametres-des-banques",
        //   text: "Paramètre des banques",
        // },
      ],
    },
    {
      text: "Comptabilité",
      tag: "ACCOUNTING",
      iconNameWithClass: "ut-coin-dollar",
      isEnabled: true,
      unavailabilityPageLink: "",
      subMenus: [
        {
          link: "/comptabilite/bordereau-de-paie",
          text: "Livre de paie",
          isEnabled: true,
          unavailabilityPageLink: "",
          // flag: "accounting_payroll_statement",
        },
        {
          link: "/comptabilite/bordereau-comptable",
          text: "Bordereau comptable",
          isEnabled: true,
          unavailabilityPageLink: "",
          // flag: "accounting_accounting_statement",
        },
        {
          link: "/conges-et-absences/provisions",
          text: "Provisions de congés payés",
          isEnabled: true,
          unavailabilityPageLink: "",
        },
      ],
    },

    {
      text: "Indicateurs RH",
      tag: "HR_INDICATORS",
      iconNameWithClass: "ut-stats-dots",
      isEnabled: true,
      unavailabilityPageLink: "",
      link: "/indicateurs-rh",
      subMenus: [],
    },

    {
      text: "Paramètres",
      tag: "SETTINGS",
      iconNameWithClass: "ut-settings",
      isEnabled: true,
      unavailabilityPageLink: "",
      subMenus: [{
        link: "/parametres/gestion-access",
        text: "Gestions des accès",
        isEnabled: true,
        unavailabilityPageLink: "",
        // flag: "setting_access_management"
      },
        {
          link: "/parametres/identite-entreprise",
          text: "Identité de l’entreprise",
          isEnabled: true,
          unavailabilityPageLink: "",
          // flag: "setting_company_identity"
        },
        // {
        //   link: "/parametres/gestion-collaborateur",
        //   text: "Gestions des collaborateurs",
        // },
      ],
    },
  ];
//
// if (ENV == "development") {
//   const indexToInsertItemTo = menus.findIndex(object => {
//     return object.iconNameWithClass === "ut-spe-team";
//   });
//   menus.splice(indexToInsertItemTo < 0 ? menus.length : indexToInsertItemTo, 0, {
//     text: "Documents",
//     iconNameWithClass: "ut-spe-folder",
//     subMenus: [
//       {
//         link: "/documents/",
//         text: "Documents entreprises",
//       },
//       {
//         link: "/corbeille/",
//         text: "Corbeille",
//       },
//     ],
//   });
//
// }
  return menus;
};

export default getMenuItems;
