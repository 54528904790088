import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse, CustomError, ErrorResponse } from "@/types/api/AxiosType";
import { AxiosError } from "axios";
import { ADD_FETCH_USER_ABSENCES_URL, FETCH_ABSENCES_TYPES_URL, FETCH_ABSENCES_URL } from "@/constants/endpoints";
import { http } from "@/utils/http";
import { AbsencesTypes, UserAbsenceType } from "@/types/api/UserAbsenceType";

// type APIResponseType = { data: UserAbsenceType[], [key: string]: any };

// http requests
export const fetchAbsencesTypes = createAsyncThunk("GET_ABSENCES_TYPES", async (_, thunkApi) => {
  return http
    .get<AxiosResponse<AbsencesTypes[]>>(FETCH_ABSENCES_TYPES_URL)
    .then(res => res.data)
    .catch(e => {
      const error = e as AxiosError<CustomError>;
      const response = error.response as unknown as AxiosResponse<ErrorResponse>;
      return thunkApi.rejectWithValue({ error: response.data });
    });
});

export const fetchAbsences = createAsyncThunk("GET_ABSENCES", async (params: { month: string, filter_by?: string }, thunkApi) => {
  const urlParams = new URLSearchParams(Object.fromEntries(Object.entries(params).filter(([_, v]) => v !== undefined && v !== "")));
  return http
    .get<AxiosResponse<UserAbsenceType[]>>(FETCH_ABSENCES_URL + `?${urlParams.toString()}`)
    .then(res => res.data)
    .catch(e => {

      const error = e as AxiosError<CustomError>;
      const response = error.response as unknown as AxiosResponse<ErrorResponse>;
      return thunkApi.rejectWithValue({ error: response.data });
    });
});

export const fetchUserAbsences = createAsyncThunk("GET_USER_ABSENCES", async (params: { id: string, month: string }, thunkApi) => {
  return http
    .get<AxiosResponse<UserAbsenceType[]>>(ADD_FETCH_USER_ABSENCES_URL.replace(":id", params.id) + `?month=${params.month}`)
    .then(res => res.data)
    .catch(e => {

      const error = e as AxiosError<CustomError>;
      const response = error.response as unknown as AxiosResponse<ErrorResponse>;
      return thunkApi.rejectWithValue({ error: response.data });
    });
});

// initial state
const initialState: {
  absencesTypes: AbsencesTypes[],
  absencesTypesStatus: string,
  absencesTypesErrors?: any,
  absences: UserAbsenceType[],
  requestedAbsences: UserAbsenceType[],
  absencesStatus: string,
  absencesErrors?: any,
} = {
  absencesTypes: [],
  absencesTypesStatus: "idle",
  absences: [],
  requestedAbsences: [],
  absencesStatus: "idle",
};

const absencesSlice = createSlice({
  name: "absences",
  initialState,
  reducers: {
    addAbsences: (state, action: PayloadAction<UserAbsenceType[]>) => {
      state.absences = [...action.payload, ...state.absences];
    },

    updateAbsence: (state, action: PayloadAction<UserAbsenceType>) => {
      let index = state.absences.findIndex(absence => absence.id === action.payload.id);
      if (index >= 0) {
        state.absences.splice(index, 1);
        state.absences.unshift(action.payload);
      }
    },

    updateRequestedAbsence: (state, action: PayloadAction<UserAbsenceType>) => {
      let index = state.requestedAbsences.findIndex(absence => absence.id === action.payload.id);
      if (index >= 0) {
        state.requestedAbsences.splice(index, 1);
        state.requestedAbsences.unshift(action.payload);
      }
    },

    deleteAbsence: (state, action: PayloadAction<string>) => {
      let index = state.absences.findIndex(absence => absence.id === action.payload);
      if (index >= 0) state.absences.splice(index, 1);
    },
  },

  extraReducers(builder) {
    // get absences
    builder
      .addCase(fetchAbsences.pending, (state) => {
        state.absencesStatus = "loading";
      })
      .addCase(fetchAbsences.fulfilled, (state, action) => {
        state.absencesStatus = "succeeded";
        if (action.meta.arg.filter_by === "requested") {
          state.requestedAbsences = action.payload.data;
        } else {
          state.absences = action.payload.data;
        }
      })
      .addCase(fetchAbsences.rejected, (state, action) => {
        state.absencesStatus = "failed";
        state.absencesErrors = action.error;
      });

    // get user absences
    builder
      .addCase(fetchUserAbsences.pending, (state) => {
        state.absencesStatus = "loading";
      })
      .addCase(fetchUserAbsences.fulfilled, (state, action) => {
        state.absencesStatus = "succeeded";
        state.absences = action.payload.data;
      })
      .addCase(fetchUserAbsences.rejected, (state, action) => {
        state.absencesStatus = "failed";
        state.absencesErrors = action.error;
      });

    // absences types
    builder
      .addCase(fetchAbsencesTypes.pending, (state) => {
        state.absencesTypesStatus = "loading";
      })
      .addCase(fetchAbsencesTypes.fulfilled, (state, action) => {
        state.absencesTypesStatus = "succeeded";
        state.absencesTypes = action.payload.data;
      })
      .addCase(fetchAbsencesTypes.rejected, (state, action) => {
        state.absencesStatus = "failed";
        state.absencesTypesErrors = action.error;
      });

  },
});

export const { addAbsences, updateAbsence, updateRequestedAbsence, deleteAbsence } = absencesSlice.actions;
export default absencesSlice.reducer;
