import { fireAlert } from "@/redux/features/alertsSlice";

const bifrostKcActionReturnHandler = (
  data: {
    tag: string, message: string, data?: { [key: string]: any }, [key: string]: any
  },
  successSignal: string,
  errorSignal: string,
  successMessage: string,
  errorMessage: string,
  callbackFunction: (...args: string[]) => void,
  dispatcher: (...args: any[]) => void,
): { kcActionFinished: boolean, returnedData?: { [key: string]: any } } => {


  let returnedData;
  const kcActionFinished = data.tag == errorSignal || data.tag == successSignal;

  if (kcActionFinished) {
    if (data.tag == successSignal) {
      dispatcher(fireAlert({
          type: "success",
          message: successMessage,
        },
      ));
      returnedData = data.data;
    } else {
      dispatcher(fireAlert({
          type: "error",
          message: errorMessage,
        },
      ));
    }
    if (callbackFunction) {
      callbackFunction();
    }
  }
  return { kcActionFinished, returnedData };
};

export const invitationsStatusMapping: { [key: string]: { [key: string]: any } } = {
  "NOT_SENT": { label: "Non défini", color: "secondary" },
  "SENT": { label: "En attente", color: "warning" },
  "CANCELLED": { label: "Annulée", color: "info" },
  "ACCEPTED": { label: "Acceptée", color: "success" },
};

export const spaceStatusMapping: { [key: string]: { [key: string]: any } } = {
  false: { label: "Désactivé", color: "secondary" },
  true: { label: "Activé", color: "success" },
};

export default bifrostKcActionReturnHandler;
