import React, {useEffect, useMemo, useState} from "react";
import {AccordionDetails} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import {useAppDispatch, useAppSelector} from "@/hooks/reduxToolkitHook";
import moment from "moment/min/moment-with-locales";
import {User} from "@/types/UserType";
import {fetchAbsencesTypes} from "@/redux/features/absencesSlice";
import {batch} from "react-redux";
import {AbsencesTypesTags} from "@/constants/absencesTypesTags";
import {AbsencesTypes, UserAbsenceType} from "@/types/api/UserAbsenceType";
import useAPI from "@/hooks/useAPI";
import {getAbsences} from "@/requests/api";
import {EventsIcons, EventsIconsColors} from "@/constants/eventsIcons";
import {getFilePath, getUserFullName} from "@/utils/utils";
import Avatar from "comp_common/avatar/Avatar";


moment.locale("fr");

export type EventProps = {
  title: string,
  count: number,
  data: { abs?: UserAbsenceType, type?: AbsencesTypes, user: User }[],
  expanded: boolean,
  handleExpanded: (v: string) => void,
  icon: EventsIconsColors
}

type ValidUser = { abs: undefined, type: undefined, user: User }

export const DayEvents = ({ selectedDate }: { selectedDate: any }) => {
  let currDate = moment(selectedDate).clone();
  const [expanded, setExpanded] = useState("");

  let isToday = moment(moment(currDate).format("YYYY-MM-DD")).isSame(moment().format("YYYY-MM-DD"));

  const dispatch = useAppDispatch();
  const { users } = useAppSelector(state => state.users);

  // UserAbsenceType[]
  const { data: absences } = useAPI(() => getAbsences(
      {
          filter_by: "valid",
          month: `${currDate.month()}-${currDate.year()}`,
          // start_date: currDate.clone().startOf("month").format("YYYY-MM-DD").toString(),
          // end_date: currDate.clone().endOf("month").format("YYYY-MM-DD").toString(),
      },
  ));

  const { absencesTypes } = useAppSelector(state => state.absences);
  const validUsers = useMemo(() => {
      const nextFifteenthDay = currDate.clone().add(15, "days");
    return users
        .filter(user => moment(user.birthday).year(currDate.clone().year()).isBetween(currDate.clone(), nextFifteenthDay, null, "(]"))
        .map(u => ({abs: undefined, type: undefined, user: u}));
  }, [users, currDate]);

    const sortBirthDates = (previousUser: ValidUser, nextUser: ValidUser) => {
        const previousUserBirthDate = moment(moment(previousUser?.user?.birthday).clone().year(currDate.clone().year()), "YYYY-MM-DD");
        const nextUserBirthDate = moment(moment(nextUser?.user?.birthday).clone().year(currDate.clone().year()), "YYYY-MM-DD");

        return previousUserBirthDate.diff(nextUserBirthDate);
    };


  let { paidLeaves, unpaidLeaves } = useMemo(() => {
    let paidLeaves: EventProps["data"] = [];
    let unpaidLeaves: EventProps["data"] = [];

      if (absencesTypes && absences && users) {
      const _validAbsences = absences.filter(abs => moment(moment(currDate).format("YYYY-MM-DD")).isBetween(abs.start_date, abs.end_date, null, "[]"));
      const _validAbsencesWithDetails: EventProps["data"] = _validAbsences.map(abs => ({
        abs,
        type: absencesTypes?.find(a => a.id == abs.type)!,
          user: users.find(u => u.id == abs.user)!,
      }));

          paidLeaves = _validAbsencesWithDetails.filter(vAbs => vAbs?.type?.value == AbsencesTypesTags.PAID);
      unpaidLeaves = _validAbsencesWithDetails.filter(vAbs => vAbs?.type?.value != AbsencesTypesTags.PAID);
    }

      return {
      paidLeaves,
      unpaidLeaves,
    };
  }, [absencesTypes, absences, users, currDate]);

    const handleExpanded = (value: string) => {
    setExpanded(exp => exp == value ? "" : value);
  };

    useEffect(() => {
    batch(() => {
      dispatch(fetchAbsencesTypes());
    });
  }, []);

    return (
        <div className="mx-7">
            <h1 className="text--S4--semi mb-4">
                Évènements à venir
            </h1>
            <div>

                {/* birthday */}
                {validUsers.length && users.length ? <Event
                    expanded={expanded == "Anniversaire"}
                    title="Anniversaire"
                    count={validUsers.length}
                    data={validUsers.sort(sortBirthDates)}
                    handleExpanded={handleExpanded}
                    icon={EventsIcons.BIRTH}
                /> : null}

                {/* paid absences */}
                {paidLeaves.length && users.length ?
                    <Event
                        expanded={expanded == "Congés Payés"}
                        title="Congés Payés"
                        count={paidLeaves.length}
                        data={paidLeaves}
                        handleExpanded={handleExpanded}
                        icon={EventsIcons.PAID}
                    /> : null}


                {/* unpaid absences */}
                {unpaidLeaves.length && users.length ?
                    <Event
                        expanded={expanded == "Absences"}
                        title="Absences"
                        count={unpaidLeaves.length}
                        data={unpaidLeaves}
                        icon={EventsIcons.SICK}
                        handleExpanded={handleExpanded}
                    /> : null}

                {
                    !validUsers.length && !paidLeaves.length && !unpaidLeaves.length ?
                        <>
                            <img src={getFilePath("empty_search.svg")} className="mt-6 w-28 mx-auto" alt="No event"/>
                            <p className="text-gray-400 text-center mt-3">Aucun évènement</p>
                        </> : null
                }

            </div>
        </div>
  );
};

const Event = (props: EventProps) => {

    const {
    title,
    count,
    data,
    expanded,
    handleExpanded,
    icon,
  } = props;

    return (
        <MuiAccordion
            disableGutters
            elevation={0}
            expanded={expanded}
            id={title}
            className={`my-[10px] cursor-pointer !rounded hover:!bg-blue-100 ${expanded ? "!bg-blue-100" : "!bg-blue-50"} `}
            onChange={() => handleExpanded(title)}
            sx={{
                "&:before": {
                    display: "none",
                },
            }}
        >
            <MuiAccordionSummary
                aria-controls={title}
            >
                <div className="flex items-center rounded-md">
                    <i
                        className={`${expanded ? "ut-arrow-drop-down" : "ut-arrow-drop-right"} text-gray-400 mr-2`}/>

                    <div
                        className={`${icon.iconBgColor} px-3 py-3 mr-4 rounded-full flex justify-center items-center`}>
                        <i className={`${icon.name} ut--lg ${icon.iconColor}`}/>
                    </div>

                    <div className="flex flex-col justify-start">
                        <h1 className="text--S5 !font-medium text-blue-700">{title}</h1>
                        <h1
                            className="text-gray-400 text-xs">{count} {count > 1 ? "collaborateurs" : "collaborateur"}</h1>
                    </div>
                </div>
            </MuiAccordionSummary>
            <AccordionDetails className="bg-blue-50">

                <div className="pl-6">
                    {data.map(value => (
                        <div
                            key={value.user.username}
                            className={"flex items-center rounded-md py-[2px]"}
                        >
                            {/*<div
                className={`py-2 mr-4 rounded-full flex justify-center items-center`}>
                <img
                  className="flex w-[32px] h-[32px] max-w-[32px] max-h-[32px] rounded-full object-cover"
                  src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt={"profile picture"} />
              </div>*/}
                            <div className="menu-avatar mr-4">
                                <Avatar user={value.user}/>
                            </div>
                            <div className="flex flex-col justify-start">
                                <h1
                                    className="text--S6 !font-medium text-blue-700">{getUserFullName(value.user)}</h1>
                                <h1
                                    className="text-gray-400 text-xs">{value.abs ? moment(value.abs.start_date).format("L") + "-" + moment(value.abs.end_date).format("L") : moment(value.user.birthday).format("ll")}</h1>
                            </div>
                        </div>
                    ))}
                </div>
            </AccordionDetails>
        </MuiAccordion>
  );
};

export default DayEvents;
