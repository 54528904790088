import { UserAwardsType, UserWithAwardType } from "@/types/api/UserAwardsType";
import _ from "lodash";
import { getSingleUser } from "@/utils/utils";
import { User } from "@/types/UserType";

/**
 * Define comparator to sort in the data table
 * @param a
 * @param b
 * @param orderBy
 */
export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Order type: ascending : asc or descending: desc
 */
export type Order = "asc" | "desc";

/**
 * Comparator for descending or ascending order
 * @param order
 * @param orderBy
 */
export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Method for cross browser compatibility (from MUI)
 * @param array
 * @param comparator
 */

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(array: T [], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


/**
 * Function for grouping an awards list by they user they are linked to
 * Return user data ( object ) enhanced with user_awards attribute
 * @param awardsList
 * @param usersList
 */
export const groupAwardsByUser = (awardsList: UserAwardsType[] = [], usersList: User[]) => {
  const tmp = _.groupBy(awardsList!, (award => award.user));
  return Object.entries(tmp).flatMap(([user_id, awards]) => {
    const user = getSingleUser(user_id, usersList);
    return user ? { user: user, awards: awards } : [];
  });
};

/**
 * get total amount of award for specific user
 */

export const getTotalAwardAmountUser = (elt: UserWithAwardType): number => {
  return !elt.user ? 0 : elt.awards.reduce((accumulator, prime) => {
    const baseAmount = (prime.amount_calcul_basis == "CATEGORY_SALARY" ? elt.user.contract.category_salary : elt.user.contract.salary) as number;
    let amount = prime.percentage ? (baseAmount * prime.percentage) / 100 : prime.amount;
    return (amount ?? 0) + accumulator;
  }, 0);
};

/**
 * Define utils functions
 */
export const getAwardTotalAmount = (elts: UserWithAwardType[]): number => {
  return elts.reduce((acc, user) => {
    let ad = acc + getTotalAwardAmountUser(user);
    return ad;
    }, 0);
};

export const getNumberUserReward = (elts: UserWithAwardType[]): number => {
  const rewardsUser = elts.filter(user => user.awards.length > 0);
  return rewardsUser.length;
};

export const getCountRewards = (users: UserWithAwardType[]): number => {
  return users.reduce((acc, user) => {
    return user.awards.length + acc;
  }, 0);
};

