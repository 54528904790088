import React from 'react';
import {useHistory} from 'react-router-dom';
import {getFilePath} from "@/utils/utils";

const Page404 = () => {

  const history = useHistory();

  return (
    <div className="h-full w-full flex items-center justify-center px-[15%] bg-gray-100">
      <div className="w-full h-screen flex items-center justify-between">
        <div className="">
          <h1 className="text-[116px] font-bold text-blue-800">Oops!</h1>
          <p className="mt-5">Quelque chose s’est mal passée ou la page <br/> auquelle vous tentez d’acceder n’existe pas</p>
          <button className='mt-6 text-blue-500' onClick={() => history.push('/')}>Retourner en lieu sûr</button>
        </div>
        <div>
          <img src={getFilePath("404.svg")}/>
        </div>
      </div>
    </div>
  );
}

export default Page404;
