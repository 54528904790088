import React, {ChangeEventHandler} from "react";
import {InputAdornment, TextField} from "@mui/material";
import {SearchRounded} from "@mui/icons-material";

export type SearchBarPropsType = {
    iconPos?: "left" | "right";
    className?: string;
    placeholder?: string;
    extendable?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>
}

// TODO: use the iconPos props to switch the icon position

const SearchBar = ({
                       iconPos = "left",
                       className,
                       placeholder = "Rechercher",
                       extendable = false,
                       onChange
                   }: SearchBarPropsType) => {
    const options = {
        InputProps: {

            startAdornment: <InputAdornment position="start">
                <SearchRounded/>
            </InputAdornment>,
        },
    };
    return (
        <TextField
            sx={extendable ? null : {width: 450}}
            className={`${className}`}
            placeholder={placeholder}
            onChange={onChange}
            {...options}
        />
    );
};

export default SearchBar;
