import React, {useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import {useAppSelector} from "@/hooks/reduxToolkitHook";
import {http} from "@/utils/http";
import {CHANGE_COMPANY_URL, KEYCLOAK_ACTIONS_TASK_STATUS_URL} from "@/constants/endpoints";
import {Link} from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import keycloak from "@/utils/keycloakUtils";
import useWebSocket from "react-use-websocket";
import bifrostKcActionReturnHandler from "pag_collabo/settings/accessManagement/utils/utils";
import {useDispatch} from "react-redux";
import {fireAlert} from "@/redux/features/alertsSlice";
import {getFilePath} from "@/utils/utils";

const SwitchCompany = () => {
    const { userInfos } = useAppSelector(state => state.users);
    const [selectedCompany, setCompany] = useState(userInfos.attributes?.company[0]);


    const switchCompanyRequestStatusSocketState = useState(false);
    const [switchCompanyRequestTaskId, setSwitchCompanyRequestTaskId] = useState(null);
    const dispatch = useDispatch();
    const invitationSocketUrl = KEYCLOAK_ACTIONS_TASK_STATUS_URL.replace(":task_id", switchCompanyRequestTaskId!) + `?key=${keycloak.token}`;
    useWebSocket(
      invitationSocketUrl,
      {
          share: true,
          onOpen: () => {
              console.log(`Connection opened to ${invitationSocketUrl}`);
          },
          onMessage: (e) => {

              const data = JSON.parse(e.data);
              const { kcActionFinished, returnedData } = bifrostKcActionReturnHandler(
                data,
                "KEYCLOAK_ACTIONS_FINISHED",
                "KEYCLOAK_ACTIONS_FAILED",
                data.message || "Le changement de la compagnie est terminé.",
                data.message || "Une erreur est survenue lors du changement de compte compagnie.",
                () => {
                    switchCompanyRequestStatusSocketState[1](false);
                    setSwitchCompanyRequestTaskId(null);
                },
                dispatch,
              );
              if (kcActionFinished && returnedData) {
                  setTimeout(() => {
                      window.location.href = "/dashboard";
                  }, 2000);
              }
          },
          onClose: () => {
              console.log(`Connection close to ${invitationSocketUrl}`);
          },
          shouldReconnect: () => true,
          reconnectInterval: (attemptNumber) =>
            Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
          reconnectAttempts: 5,
      },
      switchCompanyRequestStatusSocketState[0],
    );

    const changeCompany = async () => {
        try {
            if (selectedCompany === userInfos.attributes?.company[0]) {
                dispatch(fireAlert({
                      type: "success",
                      message: `Continuer avec la compagnie ${selectedCompany}`,
                  },
                ));
                setTimeout(() => {
                    window.location.href = "/dashboard";
                }, 1300);
                return;
            }
            http
              .post(CHANGE_COMPANY_URL.replace(":id", userInfos.attributes?.account[0]) + `?company=${selectedCompany}`)
              .then(res => {
                  const { data: { task_id: task_id } } = res.data;
                  setSwitchCompanyRequestTaskId(task_id);
                  switchCompanyRequestStatusSocketState[1](true);
              })
              .catch((err) => {
                  console.log(err);
                  let message = "Une erreur s' est produite";
                  if (err.data && err.data.detail) {
                      message = err.data.detail;
                  }
                  dispatch(fireAlert({ type: "error", message }));
              });
        } catch (e) {
            console.log(e);
        }
    };

    return (
      <div className="min-h-screen max-h-screen w-full grid grid-cols-2  bg-gray-100">

          <div className="col-span-3 md:col-span-2 lg:col-span-2 px-8 py-8 h-full  flex flex-col justify-between">
              <div className="flex flex-col text-center items-center justify-center h-full">
                  <img src={getFilePath("Normal-NoTagline.svg")} className="mb-10" style={{width: 218, height: 45}}
                       alt="Logo Uptimise"/>
                  <h3 className="text-2xl mb-6 font-semibold" style={{ fontSize: 26 }}>Sélectionner une
                      l' entreprise </h3>
                  <div className="bg-white p-8">


                      <div className=" rounded w-[440px] mb-7 cursor-pointer">

                          <div style={{ overflowY: userInfos.attributes?.companies?.length > 4 ? "auto" : "hidden" }}>
                              {userInfos.attributes?.companies?.map((company: string) => (
                                <MenuItem key={company} value={company}>
                                    <div
                                      style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          width: "100%",
                                          height: 56,
                                      }}
                                      className={"form--custom justify-between"}
                                      onClick={() => setCompany(company)}
                                    >
                                        <span>{company}</span>
                                        {selectedCompany === company &&
                                        <CheckIcon color={"primary"} className={"mr-3"} />}
                                    </div>
                                </MenuItem>
                              ))}
                          </div>
                          <div className="px-3 py-2">
                              <Button variant="contained" style={{ width: "100%", height: 56 }}
                                      disabled={!selectedCompany}
                                      onClick={changeCompany}>Continuer avec ce compte</Button>
                          </div>
                          <div className="mb-3 pt-3 px-3">
                              <Link to={"/"}><Button variant="outlined"
                                                     style={{ width: "100%", height: 56 }}>Retour</Button></Link>
                          </div>

                      </div>

                  </div>


              </div>
              {/*<div className="hidden md:flex lg:flex items-center justify-between">*/}
              {/*  <h3>*/}
              {/*    Copyright © 2020 <span className="text-blue-500">Uptimise</span>{" "}*/}
              {/*    Tout droit réservé*/}
              {/*  </h3>*/}
              {/*  <h3>Terms & Conditions</h3>*/}
              {/*</div>*/}
          </div>
      </div>
    );
};

export default SwitchCompany;
