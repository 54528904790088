import React from "react";
import {getFilePath} from "@/utils/utils";


const PageError = () => {
  return (
    <div className="h-full w-full flex flex-col items-center bg-white">
      <div className="text-center">
          <img src={getFilePath("error-oops.svg")}/>
        <p className="mt-16 text-center text-gray-400">Quelque chose s’est mal passée ou
          la page <br /> à laquelle vous tentez d’acceder n’existe pas</p>
        <a className="mt-3 text-blue-500 underline text-base" href="/">Revenir en lieu
          sûr </a>
      </div>
    </div>
  );
};

export default PageError;
