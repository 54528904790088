import {CitizenShip, Countries} from "comp_common/dropdown-countries/Countries";
import {CountryCode} from "comp_common/dropdown-countries/CountryCode";
import {Contract, StepStatusType, User} from "@/types/UserType";
import {
  AutomatiqueAwardsRulesType,
  AwardsTypes,
  BASE_CALCUL_BONUS,
  UserAwardsType,
  UserWithAwardType,
} from "@/types/api/UserAwardsType";
import _, {upperCase} from "lodash";
// @ts-ignore
import moment from "moment/min/moment-with-locales";
import {FillDefaultValue} from "comp_common/chart/DashboardChart";
import {REGISTRATION_STEPS} from "@/constants/settings";
import {AWARD_TYPES_POSSIBLE_VALUES, SENIORITY_AWARD_TYPE_MODEL} from "@/constants/userAwardTypes";
import {UserPaysheet} from "@/types/paysheetDataType";
import {AWARD_FREQUENCIES} from "@/constants/userAwards";
import {groupAwardsByUser} from "pag_collabo/compensation-elements/bonus/utils/utils";
import {NumberToLetter} from "@/utils/convertNumberToWord";
import {SERVICES_BASE_URL} from "@/constants/endpoints";
import mapper, {MapperKeysType} from "@/constants/endpoints-json/mapper";

export type ExceptionalRemuneration = { type: string, amount: number }

export const getInitials = (name: string) => {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

export const getMediumRemunerations = (users: User[], paySheets: UserPaysheet[]) => {
  return users.map((user) => {
    const salaries = paySheets.filter((paySheet) => paySheet.user === user.id);
    const divider = salaries.length;
    const salariesSum = salaries.reduce((acc, curr) => acc + curr.content["RMB"].salary_amount, 0);
    const mediumSalary = salariesSum / divider;
    return {
      user: user.id,
      salary: mediumSalary,
    };
  });
};

export const getUserFullName = (user: User | undefined, reverse = false, twoLetter = false) => {
  if (!user) return "";
  let last_name = twoLetter ? user?.last_name.split(" ")[0] : user?.last_name;
  let first_name = twoLetter ? user?.first_name.split(" ")[0] : user?.first_name;
  if (reverse)
    return `${last_name} ${first_name}`;
  return `${first_name} ${last_name}`;
};

export const getContractType = (user: User) => {
  const contractKind = [
    { value: "APPRENTISSAGE", text: "Contrat d'apprentissage" },
    { value: "PRESTATION", text: "Contrat de prestation" },
    { value: "CDD", text: "Contrat à Durée Déterminée (CDD)" },
    { value: "CDI", text: "Contrat à Durée Indéterminée (CDI)" },
  ];
  if (!isObjectEmpty(user.contract)) {
    const foundKind = contractKind.find(item => {
      return item.value === user.contract?.kind;
    });
    return foundKind?.text;
  } else {
    return "Contrat non renseigné";
  }
};

export const getUserIban = (user: User) => {
  const bank_detail = user.bank_account_details;
  return upperCase(`${bank_detail.bank_code} ${bank_detail.box_code} ${bank_detail.account_number} ${bank_detail.rib}`);
};

export const getSingleUser = (id: string, users: User[]): User | undefined => {
  return users.find(user => user.id === id);
};

export const searchStringInArray = (searchArr: User[], searchString: string) => {
  return searchArr.filter((item: User) => {
    return (
      item.last_name +
      item.first_name +
      item.usual_name +
      item.contract?.job_designation
    )
      .toLowerCase()
      .includes(searchString.toLowerCase());
  }).sort((a, b) => a.last_name.localeCompare(b.last_name))
    .sort((a, b) => Number(a.is_active) - Number(b.is_active)) ?? [];
};

export const getUserPhone = (user: User) => {
  return user?.mobile_phone ? user.mobile_phone : "+225 XX XX XX XX XX";
};

export const getUserBirthDay = (user: User) => {
  return user.birthday ? user.birthday : "Non renseigné";
};

export const getUserPositionInCompany = (user: User) => {
  return user?.contract?.job_designation
    ? user.contract.job_designation
    : "Poste non renseigné";
};

export const colorArray = [
  "#1abc9c",
  "#00695c",
  "#9e9d24",
  "#827717",
  "#2ecc71",
  "#166534",
  "#3498db",
  "#0891b2",
  "#8e44ad",
  "#6b21a8",
  "#e67e22",
  "#e74c3c",
  "#bf360c",
  "#be123c",
];

export const setUpperCase = (event, setValue) => {
  event.preventDefault();
  const upperCased = event.target.value.toUpperCase();
  const name = event.target.name;
  setValue(name, upperCased);
};

export const capitalize = (s: string) => {
  let tmp = s.toLowerCase();
  return tmp[0].toUpperCase() + tmp.slice(1);
};

export const capitalizeText = (s: string) => {
  const str = s.split(" ");
  return str.map(elt => capitalize(elt)).join(" ");
};

export const isRegistrationCompleted = (enrollment_tab: StepStatusType[]) => {
  const tabLength = enrollment_tab.length;
  return tabLength >= 3;
};

export const isObjectEmpty = (obj: object) => {
  let isEmpty = true;
  if (obj) isEmpty = Object.keys(obj).length === 0;
  return isEmpty;
};

export const isUserRegistrationCompleted = (enrollment_tab: StepStatusType[], is_old?: boolean) => {
  const tabLength = enrollment_tab.length;
  if (tabLength >= 3) {
    if (is_old) return enrollment_tab[3]?.step == 2;
    return enrollment_tab[2]?.step == 2;
  }
  return false;
};

export const progressSummary = (enrollmentStatus: StepStatusType[], registrationSteps: typeof REGISTRATION_STEPS, is_old: boolean = false) => {
  const steps = registrationSteps.filter(step => !step.seniority || step.seniority == is_old);

  return steps.map((elt, i) => {
    const tmp = i >= enrollmentStatus.length;
    return {
      title: elt.label,
      progress: tmp ? 0 : enrollmentStatus.at(i)!.step * 100 / elt.steps.length,
      status: tmp ? false : enrollmentStatus.at(i)!.is_completed,
      step: null,
      path: tmp ?
        enrollmentStatus.at!(i - 1)?.is_completed ?
          elt.steps.at(0)?.path : null
        : enrollmentStatus.at(i)!.is_completed ?
          elt.steps.at(0)!.path : elt.steps.at(enrollmentStatus.at(i)!.step)!.path,
    };
  });
};

// { [key: string]: any }
export const getFormData = (object: { [key: string]: any }): FormData => {
  let formData = new FormData();
  for (let key in object) {
    if (object[key])
      formData.append(key, object[key]);
  }
  return formData;
};

export const getFormDataWithBlank = (object: { [key: string]: any }) => {
  let formData = new FormData();
  for (let key in object) {
    if (object[key] != undefined && object[key] != null)
      formData.append(key, object[key]);
  }
  return formData;
};

/**
 *
 * @param {number} baseAmount
 * @param {number} percentage
 * @param {string} type
 * @returns
 */
export const calculateValue = (baseAmount: number, percentage: number, type: string) => {
  if (type === "SBRUTE") {
    return (percentage * baseAmount) / 100;
  }
  // to be define
  return (percentage * baseAmount) / 100;
};

export const kRoundDown = (amount: number, base: number) => {
  return Math.round(amount - amount % base);
};

export const getITS = (salaire_brut: number) => {
  let found_palier, impot, palier, palier_index, taux;
  palier = [500000, 250000, 150000, 60000, 0];
  taux = [30, 19, 15, 10, 0];
  impot = 0;
  found_palier = 0;
  palier_index = 0;
  salaire_brut = kRoundDown(Number(salaire_brut), 1000);

  if (salaire_brut === 0) return 0;

  for (var i = 0; i < palier.length; i += 1) {
    if (salaire_brut > palier[i]) {
      found_palier = palier[i];
      palier_index = i;
      impot = ((salaire_brut - found_palier) * taux[i]) / 100;
      break;
    }
  }

  for (var i = palier_index; i < palier.length - 1; i += 1) {
    impot += ((palier[i] - palier[i + 1]) * taux[i + 1]) / 100;
  }

  return impot;
};

export const itsWithAdditionalFees = (average_salary: number, salary_brut: number, exceptional_remuneration: ExceptionalRemuneration[]) => {
  let impot, r_excep = 0;
  for (var i = 0; i < exceptional_remuneration.length; i += 1) {
    if (exceptional_remuneration[i].type == "ending_indemnity")
      r_excep += exceptional_remuneration[i].amount * .75;
  }
  impot = (getITS(kRoundDown(average_salary, 1000)) * salary_brut) / average_salary;

  return kRoundDown(impot, 10);
};

export const getChargesSalariales = (type: "impot" | "total_charge" | "assurance_retraite", salaire_brut: number) => {

  const assurance_retraite = salaire_brut * 0.036;
  const its = getITS(salaire_brut);
  // console.log(its, 555, " ", assurance_retraite);
  switch (type) {
    case "assurance_retraite":
      return assurance_retraite;
    case "impot":
      return its;
    case "total_charge":
      return its + assurance_retraite;
  }
};

export const getChargesPatronale = (type: "prestation_fam" | "versement_patro" | "assurance_retraite" | "risque_pro" | "total_charge", salaire_brut: number, risque_pro: number) => { // le risque pro en pourcentage
  const prestation_fam = salaire_brut * 0.09; //le pourcentage des prestation familiales au Bénin est de 9%
  const vps = salaire_brut * 0.04; //le pourcentage des prestation familiales au Bénin est de 4%
  const assurance_retraite = salaire_brut * 0.064; //le pourcentage des assurance retraite au Bénin est de 6.4%
  const cnpsRisque_pro = salaire_brut * (risque_pro / 100); //le risque professionel est variable Bénin
  switch (type) {
    case "prestation_fam":
      return prestation_fam;
    case "versement_patro":
      return vps;
    case "assurance_retraite":
      return assurance_retraite;
    case "risque_pro":
      return cnpsRisque_pro;
    case "total_charge":
      return prestation_fam + vps + assurance_retraite + cnpsRisque_pro;
  }
};

export const getCollaboPerSex = (sex: string, userArray: User[]) => {
  const sexCount =
    Array.isArray(userArray) && userArray.length !== 0
      ? userArray.filter(user => user?.sex === sex).length
      : 0;
  return sexCount;
};

export const getCountryCallName = (code: string, shoulbeBeCountry = true) => {
  let key;
  let data = shoulbeBeCountry ? CitizenShip : Countries;
  for (key in data) {
    if (key === code) {
      return data[key];
    }
  }
};

export const getCountryDialCode = (code: string) => {
  const country = CountryCode.find(c => {
    return c.code === code;
  });
  return country?.dial_code;
};

export const formatMoney = (amount: number, format = true) => {
  let formattedNumber = amount;
  let parts;
  if (format) {
    formattedNumber = Math.round(amount ?? 0);
  }
  parts = (formattedNumber ?? 0).toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return parts.join(".");
};

export const diffDays = (start: string, end: string) => {
  return Math.abs(moment(start, "YYYY-MM-DD")
    .startOf("day")
    .diff(moment(end, "YYYY-MM-DD")
        .startOf("day"),
      "days")) + 1;
};

export const formatDate = (date: string) => {
  // console.log(date.toString());
  return moment(date).format("L");
};

export const parseStringFromLocalStorage = (item: string) => {
  const arr = localStorage.getItem(item);
  return arr ? JSON.parse(arr) : [];
};

export const updateUserVersionInArray = (arr: User[], newVersion: User) => {
  return arr.filter((user: User) => user.id !== newVersion.id);
};

export function kFormatter(num: number) {
  return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000) + " k" : Math.sign(num) * Math.abs(num);
}

{/* Si le nombre d'employé > 20 récupérer la date du dernier versement pour déterminer le prochain */
}

export function getPaymentDueDate(startDate: any, collaboCount: number) {
  // startDate: Date du premier versement
  // collaboCount: Nombre d'employés
  const monthDiff = moment().diff(moment(startDate), "months");
  const currentDay = +moment().format("D");
  if (collaboCount < 25) return currentDay < 15 ? "15 " + moment().format("MMMM YYYY") : "15 " + moment().add(1, "M").format("MMMM YYYY");
  const mod = monthDiff % 3;
  console.log(monthDiff);
  console.log(mod);
  if (mod === 0) {
    return currentDay <= 15 ? "15 " + moment().format("MMMM YYYY") : "15 " + (moment().add(3, "M")).format("MMMM YYYY");
  }
  // let nextTrimestrialMonth = "";
  // return nextTrimestrialMonth = mod === 1 ? "15 " + (moment().add(2, "M")).format("MMMM YYYY") : "15 " + (moment().add(1, "M")).format("MMMM YYYY");
  return mod === 1 ? "15 " + (moment().add(2, "M")).format("MMMM YYYY") : "15 " + (moment().add(1, "M")).format("MMMM YYYY");
}

export function getPyramideAgeData(users: User[]) {
  let ranges = [{ name: "18 - 23ans", nb: 0 }, { name: "24 - 30ans", nb: 0 }, {
    name: "31 - 40ans",
    nb: 0,
  }, { name: "41 - 50ans", nb: 0 }, { name: "50ans - plus", nb: 0 }];

  const checkrange = (age: number) => {
    if (age >= 18 && age <= 23) return ranges[0].nb += 1;
    if (age >= 24 && age <= 30) return ranges[1].nb += 1;
    if (age >= 31 && age <= 40) return ranges[2].nb += 1;
    if (age >= 41 && age <= 50) return ranges[3].nb += 1;
    if (age > 50) return ranges[4].nb + 1;
  };

  users.forEach(item => checkrange(moment().diff(item.birthday, "years")));

  return { color: FillDefaultValue.mix1, data: ranges, max: 100 };

}

export function getEffectifCollabo(users: User[], monthsNumber = 4) {
  const months = [];
  let effectif: any = [];

  for (let i = 0; i < monthsNumber; i++) {
    months.push(moment().subtract(i, "months").endOf("month"));
  }
  months.reverse();

  months.forEach(item => {
    let itemMonthEffectif = { name: capitalize(moment(item).format("MMM YYYY")), hommes: 0, femmes: 0, total: 0 };

    users.forEach((user: User, i: number) => {
      let start = user?.contract?.start;
      let end = user?.contract?.end;
      const conditon = end != null ? moment(item).isBetween(start, end) : moment(item).isAfter(start, "days");

      if (conditon == true) {
        user.sex == "M" ? itemMonthEffectif.hommes += 1 : itemMonthEffectif.femmes += 1;
      }
      if (conditon == true) itemMonthEffectif.total += 1;

      if (i == users.length - 1) {
        effectif.push(itemMonthEffectif);
      }
    });
  });
  return { color: FillDefaultValue.mix1, data: effectif, max: 100 };
}

const statusCheckingForCDIUsers = (value: boolean | undefined, contractKind: string) => {
  if (!value && contractKind === "CDI") return true;
  return value;
};

const isUserActive = (contract: Contract, startOfRange: moment.Moment, endOfRange: moment.Moment): boolean => {
  if (!contract || !contract.start) return false;

  if (moment(contract.start).isSameOrBefore(startOfRange) && statusCheckingForCDIUsers(moment(contract.out_date || contract.end).isAfter(startOfRange), contract.kind)) {
    return true;
  } else if (moment(contract.start).isSameOrAfter(startOfRange) && statusCheckingForCDIUsers(moment(contract.out_date || contract.end).isAfter(endOfRange), contract.kind)) {
    return true;
  } else if (moment(contract.start).isSameOrAfter(startOfRange) && statusCheckingForCDIUsers(moment(contract.out_date || contract.end).isSameOrBefore(endOfRange), contract.kind)) {
    return true;
  } else {
    return false;
  }
};

export function getHiringAndLeavingCount(users: User[]) {
  const months = [];
  let counts: any = [];
  const totals = {
    leaves: 0,
    newHires: 0,
  };
  for (let i = 0; i < 6; i++) {

    months.push(moment().subtract(i, "months").endOf("month"));
  }
  months.reverse();

  const startOfRange = months[0].clone().startOf("month");
  const endOfRange = [...months].pop().clone().endOf("month");

  const filteredUsers = users.filter(user => {
    return user.contract && isUserActive(user.contract, startOfRange, endOfRange);
  });


  months.forEach(item => {
    const startOfMonth = item.clone().startOf("month");
    const endOfMonth = item;


    let itemMonthCount = {
      name: capitalize(moment(item).format("MMM YYYY")),
      activeUsersInMonth: 0,
      "Départ": 0,
      "Nouvelles embauches": 0,
      "Effectif total": 0,
    };

    filteredUsers.forEach((user: User) => {
      if (moment(user.first_contract || user.contract.start).isBetween(startOfMonth, endOfMonth)) {
        itemMonthCount["Nouvelles embauches"] += 1;
      }

      if (moment(user.contract.out_date || user.contract.end).isBetween(startOfMonth, endOfMonth)) {
        itemMonthCount["Départ"] += 1;
      }

      if (isUserActive(user.contract, startOfMonth, endOfMonth)) {
        itemMonthCount.activeUsersInMonth += 1;
      }
    });
    itemMonthCount["Effectif total"] = itemMonthCount.activeUsersInMonth - itemMonthCount["Départ"];
    // @ts-ignore
    delete itemMonthCount.activeUsersInMonth;

    counts.push(itemMonthCount);
  });

  counts.forEach((count) => {
    totals.leaves += count["Départ"];
    totals.newHires += count["Nouvelles embauches"];
  });

  return { color: FillDefaultValue.mix3, data: counts, totals: totals, max: 100 };
}

export const toCapitalizeCase = (str: string) => {
  let _str = str.split("");
  _str[0] = _str[0].toUpperCase();

  return _str.join("");
};

export const constantsToDropdownOptions = (obj: { [key: string]: { value: string, label: string } }) => {
  return Object.keys(obj).map(o => ({ ...obj[o] }));
};

export function getChargeVsSalary(paysheets: any[] | undefined) {
  const months = [];
  for (let i = 0; i < 4; i++) {
    months.push(moment().subtract(i, "months").endOf("month"));
  }
  months.reverse();
  const groupedByMonth = _.groupBy(paysheets, sheet => sheet.date);
  const getUsersChargeAndSalaries = () => {
    let arrayOfCharges = [];
    for (const [key, value] of Object.entries(groupedByMonth)) {
      let obj = {
        name: key,
        salary: value.reduce((acc, item) => acc + (item.content.FEES.salary_amount), 0),
        charge: value.reduce((acc, item) => acc + (item.content.CNPS.patronal_amount + item.content.TAXE.patronal_amount), 0),
      };
      arrayOfCharges.push(obj);
    }
    return arrayOfCharges;
  };

  const chargeAndSalaries = getUsersChargeAndSalaries();

  const finalArray: any = [];
  months.forEach(item => {
    const foundIndex = chargeAndSalaries.findIndex(el => moment(item).isSame(moment(el.name.split("-").reverse().join("-")), "month"));
    foundIndex !== -1 ?
      finalArray.push({
        ...chargeAndSalaries[foundIndex],
        name: capitalize(moment(chargeAndSalaries[foundIndex].name.split("-").reverse().join("-")).format("MMM YYYY")),
      })
      :
      finalArray.push({ name: capitalize(moment(item).format("MMM YYYY")), charge: 0, salary: 0 });
  });

  return { color: FillDefaultValue.mix2, data: finalArray, max: 100 };
}

interface getTableHeightInterfaceConfig {
  itemsLength: number,
  itemHeight: number,
  tableSize: number,
  headerAndFooterHeightsSum: number,
  defaultTableHeight: number
}

export const getSomeDataGridTableHeight = (args: getTableHeightInterfaceConfig) => {
  if (args.itemsLength < 1) return args.defaultTableHeight;
  const itemsHeightsSum = args.itemsLength > args.tableSize ? args.tableSize * args.itemHeight : args.itemsLength * args.itemHeight;
  return itemsHeightsSum + args.headerAndFooterHeightsSum;
};

interface IClassification {
  value: string,
  desc: string,
  job: string
}

export function getJobClassificationDescription(value: string): IClassification | undefined {
  let classifications = [
    {
      value: "E1",
      desc: "Travailleur exécutant des tâches élémentaires ne nécessitant aucune connaissance, ni adaptation préalable",
      job: "Manœuvre ordinaire",
    },
    {
      value: "E2",
      desc: "Travailleur exécutant des travaux simples n’exigeant pas de connaissance professionnelle et nécessitant une initiative de courte durée",
      job: "Gardien - Agent de sécurité  - Agent de l’entretien - Agent de liaison  - Manutentionnaire - Meunier - Porcher - Bouvier - Portier - Valet - Agent de catégorie E1 promu",
    },
    {
      value: "E3",
      desc: "Travailleur Possédant un minimum d’instruction et des connaissances élémentaires de la profession acquise par l’apprentissage ou la pratique",
      job: "Aide ouvrier  - Vendeur –auxiliaire - Poly copieur - Pointeur - Pompiste - Blanchisseur - Jardinier - Standardiste - Agent de la catégorie E2 promu",
    },
    {
      value: "E4",
      desc: "Travailleur d’habilité et de rendement courant exécutant des travaux qui exigent des connaissances professionnelles certaines",
      job: "Ouvrier spécialisé (maçon, électricien, Menuiser, peintre cuisinier) - Caissier  - Vendeur - Dactylographe - Opérateur de saisie - Aide-soignant - Conducteur de véhicule (permis conduit B, A)",
    },
    {
      value: "E5",
      desc: "Travailleur ayant des connaissances professionnelles, capables d’exécuter des travails publics",
      job: "Manœuvre ordinaire",
    },
    {
      value: "E6",
      desc: "Travailleur exécutant des tâches élémentaires ne nécessitant aucune connaissance, ni adaptation préalable",
      job: "Employé de bureau titulaire du CEPE plus de 2 ans d’expérience professionnelle - Agent de transit (magasinage, consignation, déclaration en douane) - Conducteur de véhicule (permis D ou E) - Agent de la catégorie E4 promu",
    },

    {
      value: "M1",
      desc: "Travailleur occupé à des travaux dont l’exécution ne nécessite pas des connaissances professionnelles spéciales",
      job: "titulaire du BAC  A, B, C, D  - Agent de la catégorie E6 promu",
    },
    {
      value: "M2",
      desc: "Travailleur d’habilité professionnelle exécutant des travaux de haute valeur professionnelle et pouvant exercer un commandement sur les ouvriers ou d’autres types d’agents",
      job: "BEPC+ 3 ans de formation professionnelle (diplôme de l’INMES) ou diplôme équivalent - Secrétaire titulaire du BAC G1 ou diplôme équivalent - Technique titulaire du BAC G2 ou diplôme équivalent - Technique titulaire du BAC G3 ou diplôme équivalent - Technique titulaire du DTI ou diplôme équivalent - Agent de la catégorie M1 promu",
    },
    {
      value: "M3",
      desc: "Travailleur capable d’exercer d’une façon permanente un commandement sur plusieurs agents de catégories inférieures. Il assure le rendement de son équipe, soit sous la direction d’un agent de maîtrise de catégorie supérieure, soit sur les directives de l’employeurs ou de son représentant. Il assure le respect des temps et de la discipline du personnel placé sous ses ordres",
      job: "Titulaire du DUEL ou du DUEG  - Agent de la catégorie M2 promu",
    },
    {
      value: "M4",
      desc: "Travailleur pouvant exercer sur les directives de l’employeur ou de son représentant et ayant sous ses ordres des agents de maîtrise de catégorie inférieure. Il a le contrôle et la responsabilité des travaux qui lui sont confiés. Il prend l’initiative dans le cas de ses fonctions.",
      job: "BAC + 3 ans de formation académique (licence) ou diplôme équivalent  - BAC + 2 ans de formation professionnelle (BTS, DUT ou diplôme équivalent)  - Agent de la catégorie M3 promu",
    },

    {
      value: "C1",
      desc: "Travailleur de formation technique administrative, Économique, financière, juridique, comptable, commerciale, possédant des connaissances approfondies de la professions acquises par des études supérieures, par une formation technique ou une longue expérience professionnelle appuyée sur des connaissances générales.",
      job: "Titulaire du diplôme de l’Ecole Nationale d’Administration et de Magistrature niveau 1 (ENAM 1) ou diplôme équivalent  - Titulaire du diplôme de l’Ecole Nationale d’Economie Appliquée et de Management niveau 1 (ENAM 1) ou diplôme équivalent - Titulaire d’une maître ou tout autre diplôme équivalent (BAC+4 ans d’études académiques)  - Agent de la catégorie M4 promu",
    },
    {
      value: "C2",
      desc: "Travailleur possédant des aptitudes pour occuper des fonctions techniques, administratives ou juridiques ou ayant une qualification professionnelle acquise par une formation technique ou universitaire appréciable.",
      job: "Titulaire d’un BAC+ 4ans de formation professionnelle  - (ingénieure des travaux) ou diplôme équivalent - Agent de la catégorie C1 promu",
    },
    {
      value: "C3",
      desc: "Travailleur technique, administratif ou commercial chargé de la supervision d’un service important dont les fonctions exigent une délégation de pouvoir. Travailleur possédant des connaissances approfondies de la profession acquises par des études adéquates au moyen d’une formation technique ou d’une longue expérience professionnelle appuyée sur des connaissances générales étendues.",
      job: "Titulaire du diplôme de l’ENAM 2 ou diplôme équivalent  - Titulaire du diplôme de l’ENEAM 2 ou diplôme équivalent - Titulaire de DESS - Titulaire du diplôme ingénieure - Agent de la catégorie C2 promu",
    },
    {
      value: "C4",
      desc: "Travailleur détenant du conseil d’Administration des pouvoirs généraux en vue d’en user pour la Direction Générale de l’Entreprise et sur toutes qui y sont exécutées. Travailleur titulaire d’une qualification post universitaire",
      job: "Titulaire d’un Doctorat ou diplôme équivalent - Directeur Général - Agent de la catégorie C3 promu",
    },
  ];

  return classifications.find(item => item.value == value);
}

export const appendSeniorityAwardToUserAwardsListIfExist = (user_id: string, seniority: number, seniorityAwardRules: AutomatiqueAwardsRulesType["seniority_award_type_rules"], awardsList: UserAwardsType[], amount: number | null = null): UserAwardsType[] => {
  const between = (x: number, min: number | null, max: number | null) => {
    if (min === null && max === null) return false;
    if (min === null) return x < max!;
    if (max === null) return x >= min;
    return x >= min && x < max;
  };

  const retrieveRateToUse = (rule: any, userSeniorityAtEndOfMonth: number) => {
    if (!rule.rate.step) return rule.rate;
    return (Math.floor(userSeniorityAtEndOfMonth / 365) - rule.rate.seniority_base + 1)
      * rule.rate.step + rule.rate.percentage_base;
  };

  if (awardsList.find(el => {
    if (_.isString(el.award_type))
      return el.award_type == AWARD_TYPES_POSSIBLE_VALUES.PRIME_ANCIENNETE;
    return el.award_type && el.award_type.value && el.award_type!.value == AWARD_TYPES_POSSIBLE_VALUES.PRIME_ANCIENNETE;
  }))
    return awardsList;

  const seniorityAtEndOfMonth = seniority + moment().endOf("month").diff(moment(), "days");
  const ruleToApply = seniorityAwardRules ? seniorityAwardRules.find(el => between(seniorityAtEndOfMonth, el.start, el.end)) : null;

  if (!ruleToApply)
    return awardsList;
  const rate = retrieveRateToUse(ruleToApply, seniorityAtEndOfMonth);
  const award: UserAwardsType = {
    id: "",
    award_details: [],
    amount: amount ? Math.round((rate * amount) / 100) : null,
    percentage: rate,
    frequency: 1,
    amount_calcul_basis: BASE_CALCUL_BONUS[0].value,
    include_in_gross_salary: true,
    other_type_label: null,
    start_date: moment().startOf("month").format(),
    end_date: null,
    user: user_id,
    award_type: { ...SENIORITY_AWARD_TYPE_MODEL },
  };
  awardsList.push(award);
  return awardsList;
};

export const appendSeniorityAwardsToAwardsList = (awardList: UserAwardsType[], users: User[], seniorityAwardRules: AutomatiqueAwardsRulesType["seniority_award_type_rules"]) => {
  const data: UserAwardsType[] = [];

  const awardedUsers = awardList.map(award => award.user);
  const userWithAwards = groupAwardsByUser(awardList, users.filter(x => awardedUsers.includes(x.id))) as UserWithAwardType[];

  userWithAwards.forEach(({ user, awards }) => {
    if (!awards.find(award => award && award.award_type && (award.award_type as AwardsTypes).value == AWARD_TYPES_POSSIBLE_VALUES.PRIME_ANCIENNETE)) {
      data.push(...appendSeniorityAwardToUserAwardsListIfExist(
        user.id,
        user.seniority_duration ? user.seniority_duration : 0,
        seniorityAwardRules,
        [],
        user && user.contract ? parseFloat(user!.contract.category_salary ?? "0") ?? user!.contract.salary : 0));
    }
  });
  awardList.push(...data);
  return awardList;
};


export const updateGrossSalaryBasedAwardsAmountForUser = (user: User, userAwards: UserAwardsType[]) => {
  const userGrossSalaryBasedAwards = userAwards.filter((award) => award.amount_calcul_basis == BASE_CALCUL_BONUS[1].value);

  userGrossSalaryBasedAwards.map((award) => {

    const grossSalary = user.contract.salary + userAwards.filter((award) => {
      return award.include_in_gross_salary;
    }).reduce((sum, award) => sum + ((award && award.amount || 0)), 0);

    if ((award.award_type as AwardsTypes).value === AWARD_TYPES_POSSIBLE_VALUES.PRIME_TREIZIEME_MOIS) {
      award.amount = award.frequency == AWARD_FREQUENCIES.ONCE ? grossSalary : Math.round(grossSalary / (12 / award.frequency!));
    } else {
      award.amount = grossSalary * award.percentage! / 100;
    }
  });
};

export const updateGrossSalaryBasedAwardsAmountInAwardsList = (awardList: UserAwardsType[], users: User[]) => {
  const awardedUsers = awardList.map(award => award.user);
  const userWithAwards = groupAwardsByUser(awardList, users.filter(x => awardedUsers.includes(x.id))) as UserWithAwardType[];

  userWithAwards.forEach(({ user, awards }) => {
    updateGrossSalaryBasedAwardsAmountForUser(user, awards);
  });
};
export const numberToWords = (number: number) => {
  return NumberToLetter(number);
};

type DataItem = Record<string, any>;
type SummaryItem = Record<string, any>;

interface FieldMapping {
  [fieldName: string]: string; // Original field name to label mapping
}

export const generateExcelData = (fieldMappings: FieldMapping, data: DataItem[], summary?: SummaryItem, totalField: string = "Totals"): any[] => {

  const firstFieldLabel = Object.values(fieldMappings)[0];

  // Map each data item to the desired Excel format
  let excelBody = data.map(item => {
    let row = {};
    for (const field in fieldMappings) {
      row[fieldMappings[field]] = item[field];
    }
    return row;
  });

  if (summary) {
    let excelFooter = { [firstFieldLabel]: "" };

    for (const field in fieldMappings) {
      excelFooter[fieldMappings[field]] = summary[field];
    }

    excelFooter[firstFieldLabel] = totalField;
    excelBody = excelBody.concat(excelFooter);
  }

  return excelBody;
};

export const deleteElementsFromObject = (initialObject: any, keys: string[]) => {
  keys.forEach(key => {
    delete initialObject[key];
  });
};

export const getServiceAndEndpointFromPath = (url: string): { serviceName: MapperKeysType, endpoint: string } | null => {
  let result = null;
  if (url.includes("localhost") || url.includes("127.0.0.1")) {
  } else {
    const regex = "\\/([\\w-]+)\\/api\\/v1\\/([\\w-]+(?:\\/[\\w-]+)*)\\/";
    const path = url.split(SERVICES_BASE_URL)[1];
    const match = path.match(regex);
    if (match) {
      result = { "serviceName": match[1] as MapperKeysType, "endpoint": `/${match[2]}` };
    }
  }
  return result;
};


const generateRegexPattern = (resourceUri: string) => {
  // Replace dynamic parts with regex patterns
  const pattern = resourceUri.replace(/{.*?}/g, "([^/]+)");
  // Add start and end anchors to match the entire string
  return new RegExp("^" + pattern + "$");
};

export const getRelatedResourceId = (serviceName: MapperKeysType, path: string, method: string) => {
  const serviceEndpoints = mapper[serviceName];
  return Object.keys(serviceEndpoints).find((endpoint) => {
    const endpointInfo = serviceEndpoints[endpoint];
    return endpointInfo.method === method.toUpperCase() && generateRegexPattern(endpointInfo.resource_uri).test(path + "/");
  });
};

export const getFilePath = (fileName: string) => {
  return `${import.meta.env.VITE_MEDIAS_BASE_URL}/${fileName}`;
};

export const downloadFileFromBlob = async (blob: any, filename: string) => {
  const url = window.URL.createObjectURL(await blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const teamsColorPalette = [
  { border: "#E6E6E6", background: "#FBFBFB" },
  { border: "#E4CCFF", background: "#FBF7FF" },
  { border: "#AFF4C6", background: "#F3FDF6" },
  { border: "#BDE3FF", background: "#F5FBFF" },
  { border: "#FFE8A3", background: "#FFFCF1" },
  { border: "#FFC7C2", background: "#FFF7F6" },
];

export const classificationLabels = {
  cadres_et_assimile: "Cadres et assimilés",
  agents_d_execution: "Agents d'executions",
  agents_de_maitrise: "Agents de maîtrise",
};

export const CNPSContributionMarks = {
  "CNPS Assurance Vieillesse": "1",
  "CNPS Accidents du Travail et maladie professionnelle": "2",
  "CNPS Prestation familiale et assurance maternité": "3",
};

